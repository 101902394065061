<template>
  <div class="cantainer">
    <el-swiper :src='imgSrc'
               :info1="info1"></el-swiper>
    <div class="container_ container1">
      <div class="main">
        <div class="part part1">
          <div class="img_div"><img src="../assets/images/sympQrcode.png"
                 alt=""></div>
          <div class="text">
            <p>尚盈名品是江苏尚盈期颐信息网络有限公司旗下的生活购物平台。</p>
            <p>长期致力于为合作企业员工、客户提供生活服务与节日福利新体验，面向各类企业提供耗材的集采、员工生日福利、团建短途游，以及家政服务、海量商品购物等。</p>
            <div class="scan_qr">
              <div class="left_arrow"></div>
              <div class="tent">微信扫码体验</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container_ container2">
      <div class="main">
        <div class="part part2">
          <div class="title">尚盈礼品卡</div>
          <div class="bgimg"></div>
          <div class="text">
            <p>为了给合作企业、商户带来更多样化的合作与联盟，尚盈名品开启云上电商新体验</p>
            <p>推出尚盈名品——尊享礼品卡，客户可在尚盈名品官方商城精选好物，通过礼品卡余额进行消费 </p>
            <p>目前，通过礼品卡的形式面向工会、企业、商户，为员工及客户提供福利与关怀</p>
          </div>
          <div class="tabs">
            <!-- 标签页 -->
            <div class="tabs_">
              <div class="tabs_item tabs_first"
                   @click="isActived =1"
                   :class="isActived==1? ' actived':''">至尊星享卡</div>
              <div class="tabs_item tabs_second"
                   @click="isActived =2"
                   :class="isActived==2? ' actived':''">尊享礼品卡</div>
            </div>
            <div class="border"></div>
            <div class="show">
              <div class="img"><img :src="giftCard.imgSrc"
                     alt=""></div>
              <div class="text">
                <div class="explain">卡片说明</div>
                <p>{{ giftCard.text1 }}</p>
                <p>{{ giftCard.text2 }}</p>
                <div class="explain">使用规则</div>
                <p>礼品卡需在绑定后的1个自然年内使用，过期作废。</p>
                <p>礼品卡不予兑现，购物金额不足部分以现金补足。</p>
                <p>礼品卡支付部分不开发票。</p>
                <!-- <p>发生退货时，礼品卡支付部分退回卡内，不予兑现。</p> -->
                <!-- <p>礼品卡一旦绑定，将只能被绑定账户使用，不能跨账户使用。</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container_ container3">
      <div class="main">
        <div class="part part3">
          <div class="title">Thousands of category</div>
          <div class="bgimg"></div>
          <div class="dsc">百大品牌 千种品类</div>
          <div class="banner"></div>
        </div>
      </div>
    </div>
    <div class="container_ container4">
      <div class="main">
        <div class="part part4">
          <div class="title">Free custom</div>
          <div class="bgimg"></div>
          <div class="dsc">多种模式 自由定制</div>
          <ul class="pics">
            <li class="pic_li"
                v-for="item in picsList"
                :key="item.title">
              <img class="img_"
                   :src="item.imgSrc"
                   alt="">
              <p>{{ item.title }}</p>
              <p>{{ item.dsc1 }}</p>
              <p>{{ item.dsc2 }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgSrc: require('@/assets/images/banner6.png'),
      info1: {
        title: '尚盈名品',
        dsc: '综合电商集采平台'
      },
      picsList: [
        { imgSrc: require('@/assets/images/piliang_caigou.png'), title: '批量采购', dsc1: '一次性采购礼品卡', dsc2: '享受折扣优惠及大额购物积分' },
        { imgSrc: require('@/assets/images/logo_dingzhi.png'), title: 'logo定制', dsc1: '根据企业logo定制', dsc2: '商城名称与礼品卡logo' },
        { imgSrc: require('@/assets/images/qiye_dingzhi.png'), title: '企业定制', dsc1: '结合员工考勤、绩效等，定制独立的小程序', dsc2: '集员工奖惩、福利于一体的综合管理平台' }
      ],
      isActived: 1,
      giftCard: {
        imgSrc: require('@/assets/images/zhizunka.png'),
        text1: '尚盈名品礼品卡(以下简称礼品卡)只能在尙盈名品商城购买产品时使用。购买产品使用的金额及配送费用将从礼品卡中扣除，每笔订单可以使用多张礼品卡，没有使用完的余额将仍然保留在礼品卡中。如果当次消费金额超过礼品卡的金额，不足部份需由顾客使用其它支付方式付清。',
        text2: '如需查看购买记录及余额，请登录商城，在“我的”页面中，选择“礼券兑换”，进入我的礼品卡页面进行绑定及查看。'
      }
    }
  },

  created () {

  },
  mounted () {

  },
  methods: {
  },
  watch: {
    isActived (val, oldVal) { // 普通的watch监听
      if (val == 1) {
        this.giftCard = {
          imgSrc: require('@/assets/images/zhizunka.png'),
          text1: '尚盈名品礼品卡(以下简称礼品卡)只能在尙盈名品商城购买产品时使用。购买产品使用的金额及配送费用将从礼品卡中扣除，每笔订单可以使用多张礼品卡，没有使用完的余额将仍然保留在礼品卡中。如果当次消费金额超过礼品卡的金额，不足部份需由顾客使用其它支付方式付清。',
          text2: '如需查看购买记录及余额，请登录商城，在“我的”页面中，选择“礼券兑换”，进入我的礼品卡页面进行绑定及查看。'
        }
      } else if (val = 2) {
        this.giftCard = {
          imgSrc: require('@/assets/images/lipinka.png'),
          text1: '尚盈名品礼品卡(以下简称礼品卡)只能在尙盈名品商城购买产品时使用。购买产品使用的金额及配送费用将从礼品卡中扣除，每笔订单可以使用多张礼品卡，没有使用完的余额将仍然保留在礼品卡中。如果当次消费金额超过礼品卡的金额，不足部份需由顾客使用其它支付方式付清。',
          text2: '如需查看购买记录及余额，请登录商城，在“我的”页面中，选择“礼券兑换”，进入我的礼品卡页面进行绑定及查看。'
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import "@/assets/styles/symp.scss";
</style>
